// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export function NerdFace2Icon(props) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 44 15"}
      height={"1em"}
      width={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M43.46 1.42S43.098.796 36.373.17C27.418-.665 24.205 1.824 23.6 2.387l-.063-.004c-.671-.042-1.308-.084-2.01-.084h-.033a41.54 41.54 0 00-1.613.074C19.234 1.779 16-.657 7.126.169.404.795.042 1.421.042 1.421L0 3.959s1.002.285 1.652 1.09c.647.794.95 5.245 2 7.337 1.628 3.248 9.721 2.426 9.721 2.426s3.302.26 4.912-2.52c1.459-2.515 2-6.037 2.088-6.68a2.67 2.67 0 011.325-.352 2.668 2.668 0 011.445.422c.116.794.668 4.176 2.078 6.606 1.61 2.777 4.911 2.52 4.911 2.52s8.093.823 9.721-2.425c1.051-2.096 1.354-6.543 2-7.338.647-.805 1.653-1.09 1.653-1.09l-.046-2.534zM17.655 8.425c-.64 2.967-1.445 4.989-6.932 4.676-3.534-.2-5.991-.194-6.557-5.348-.348-3.202.039-4.412.302-4.798.26-.384 1.206-1.495 6.673-1.301 5.467.193 6.25 1.42 6.57 1.976.015.02.025.045.04.07l.231.766c.13.763.102 1.987-.326 3.959zm21.681-.672c-.562 5.154-3.02 5.147-6.553 5.348-5.488.313-6.293-1.709-6.933-4.676-.643-2.96-.383-4.243-.06-4.795.32-.556 1.105-1.786 6.571-1.976 5.47-.194 6.413.917 6.673 1.3.26.387.65 1.597.302 4.8z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default NerdFace2Icon;
/* prettier-ignore-end */
