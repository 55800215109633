// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PlasmicItemLink2_itemLink2__typ9K {
  width: 58px;
  height: 18px;
  display: flex;
  cursor: pointer;
  position: relative;
  transition-property: all;
  transition-duration: 0.5s;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  -webkit-transition-property: all;
  -webkit-transition-duration: 0.5s;
}
.PlasmicItemLink2_home2__-asG3 {
  display: block;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  letter-spacing: 0.2px;
  line-height: 100%;
  color: rgba(255, 255, 255, 1);
  position: relative;
  top: auto;
  left: auto;
  width: 100%;
  min-width: 0;
}
.PlasmicItemLink2_itemLink2__typ9K:hover .PlasmicItemLink2_home2__-asG3 {
  color: var(--token-RAUIlqTuOwzm7NO);
}
`, "",{"version":3,"sources":["webpack://./src/components/plasmic/copy_of_blank_project/PlasmicItemLink2.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,aAAa;EACb,eAAe;EACf,kBAAkB;EAClB,wBAAwB;EACxB,yBAAyB;EACzB,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,gCAAgC;EAChC,iCAAiC;AACnC;AACA;EACE,cAAc;EACd,eAAe;EACf,iCAAiC;EACjC,gBAAgB;EAChB,qBAAqB;EACrB,iBAAiB;EACjB,6BAA6B;EAC7B,kBAAkB;EAClB,SAAS;EACT,UAAU;EACV,WAAW;EACX,YAAY;AACd;AACA;EACE,mCAAmC;AACrC","sourcesContent":[".itemLink2 {\n  width: 58px;\n  height: 18px;\n  display: flex;\n  cursor: pointer;\n  position: relative;\n  transition-property: all;\n  transition-duration: 0.5s;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  -webkit-transition-property: all;\n  -webkit-transition-duration: 0.5s;\n}\n.home2 {\n  display: block;\n  font-size: 14px;\n  font-family: \"Roboto\", sans-serif;\n  font-weight: 500;\n  letter-spacing: 0.2px;\n  line-height: 100%;\n  color: rgba(255, 255, 255, 1);\n  position: relative;\n  top: auto;\n  left: auto;\n  width: 100%;\n  min-width: 0;\n}\n.itemLink2:hover .home2 {\n  color: var(--token-RAUIlqTuOwzm7NO);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"itemLink2": `PlasmicItemLink2_itemLink2__typ9K`,
	"home2": `PlasmicItemLink2_home2__-asG3`
};
export default ___CSS_LOADER_EXPORT___;
