// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export function Vector27Icon(props) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 4 9"}
      height={"1em"}
      width={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M.402.034C-.11.204-.097.85.241 2.137a666.142 666.142 0 001.476 5.54c.085.275.317.739 1.16.465.844-.275.352-.588.141-1.347C2.86 6.229 1.394.84 1.316.565 1.19.14.786-.092.402.035z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default Vector27Icon;
/* prettier-ignore-end */
