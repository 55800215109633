// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export function IconFacebookIcon(props) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 29 29"}
      height={"1em"}
      width={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M19.938 7.25h-3.625c-.668 0-1.209.541-1.209 1.208v3.625h4.834a.423.423 0 01.41.556l-.894 2.659a.604.604 0 01-.568.41h-3.782v9.063c0 .334-.27.604-.604.604h-3.02a.604.604 0 01-.605-.604v-9.063H9.062a.604.604 0 01-.604-.604v-2.416c0-.334.27-.605.604-.605h1.813V8.458a4.833 4.833 0 014.833-4.833h4.23c.333 0 .604.27.604.604v2.417c0 .333-.27.604-.605.604z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default IconFacebookIcon;
/* prettier-ignore-end */
