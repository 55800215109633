// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export function IconInstagramIcon(props) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 29 29"}
      height={"1em"}
      width={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        fillRule={"evenodd"}
        clipRule={"evenodd"}
        d={
          "M19.333 3.625H9.667a6.042 6.042 0 00-6.042 6.042v9.666a6.042 6.042 0 006.042 6.042h9.666a6.042 6.042 0 006.042-6.042V9.667a6.042 6.042 0 00-6.042-6.042zm3.927 15.708a3.94 3.94 0 01-3.927 3.927H9.667a3.94 3.94 0 01-3.927-3.927V9.667A3.94 3.94 0 019.667 5.74h9.666a3.94 3.94 0 013.927 3.927v9.666zM20.24 9.97a1.208 1.208 0 100-2.417 1.208 1.208 0 000 2.417zm-5.74-.906a5.438 5.438 0 105.438 5.437A5.425 5.425 0 0014.5 9.062zM11.177 14.5a3.323 3.323 0 106.646 0 3.323 3.323 0 00-6.646 0z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default IconInstagramIcon;
/* prettier-ignore-end */
