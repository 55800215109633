// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export function Vector40Icon(props) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 32 30"}
      height={"1em"}
      width={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M23.893 0c-5.82 0-7.958 5.947-7.958 5.947S13.825 0 7.966 0C3.562 0-1.458 3.49.396 11.425 2.25 19.358 15.95 29.242 15.95 29.242s13.65-9.884 15.507-17.818C33.312 3.49 28.653 0 23.893 0z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default Vector40Icon;
/* prettier-ignore-end */
