// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export function Vector6Icon(props) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 28 12"}
      height={"1em"}
      width={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M27.464 1.03c-.468-.864-1.456-1.24-2.51-.913-3.15.984-7.197 1.554-11.106 1.557-3.91-.003-7.956-.573-11.106-1.557C1.69-.21.699.167.232 1.03c-.479.886-.13 1.905.27 2.791 2.257 4.957 7.232 7.925 13.314 7.942h.056c6.082-.017 11.057-2.985 13.314-7.942.408-.886.756-1.909.278-2.791z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default Vector6Icon;
/* prettier-ignore-end */
