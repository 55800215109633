// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export function Vector10Icon(props) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 41 40"}
      height={"1em"}
      width={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        opacity={".7"}
        d={
          "M21.09 2.71l.426 2.265-3.456-1.78-2.422-.453s-3.899 6.69-5.214 9.65C7.71 18.5 8.73 22.304 8.804 24.68c.052 1.62-.49 2.601-.83 3.315-.338.717-2.345 4.94-2.345 4.94s-1.905-1.052-3.84-.975c-1.655.064-1.82 1.1-1.785 1.365.039.263 2.352 3.304 5.97 4.964 3.617 1.659 5.386.89 5.386.89s1.065-.77-.18-2.318c-1.244-1.546-2.939-2.5-2.939-2.5l3.052-6.668 1.997-2.11 2.26-1.66 2.26-2.488 2.075-3.203 2.524-4.823.566-1.055s1.357 4.785 3.052 7.499c1.694 2.714 2.636 2.939 3.617 4.936.981 1.996 2.31 7.755 2.31 7.755s-1.463.475-2.83 1.27c-1.98 1.149-1.371 2.074-1.311 2.538.12.935 2.96 1.666 7.597.045 4.637-1.62 5.041-2.97 4.925-3.649-.112-.678-1.483-.928-2.675-1.005-1.083-.07-2.781.218-2.781.218l-1.206-3.854-.678-3.543.263-2.602.679-2.714.264-3.73-.605-5.277L31.9 2.63 31.16 0s-4.114.334-5.773.745c-3.354.83-4.296 1.966-4.296 1.966z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default Vector10Icon;
/* prettier-ignore-end */
