import React from "react";
import { CorefluxHub } from "../../components/CorefluxHub";
import "./style.css";

export default function Banner(props) {
  let open;
  const handleBtnClick = () => {
    const discBanner = document.getElementById("discount")
    if(discBanner.style.left === "97%" || discBanner.style.left === ""){
      discBanner.style.left = "28%"
      discBanner.classList.add("open-banner")
      open = setTimeout(() => {
        discBanner.style.left = "97%"
        discBanner.classList.remove("open-banner")
      }, 3000)
    }else{
      clearTimeout(open)
      discBanner.style.left = "97%"
      discBanner.classList.remove("open-banner")
    }
  }
  return (
    <div className="overlap-group-banner" id="discount">
      <div className="group">
        <button className="btn-open" onClick={handleBtnClick}>
          <img
            className="vector"
            alt="Vector"
            src="https://generation-sessions.s3.amazonaws.com/61ec5fbb8e0d78defedd7b9fd5a4be41/img/vector-5.svg"
          />
        </button>
      </div>
      <div className="rectangle">
      <div className="div">
    <div className="group-2">
    <h1 className="two-easy-steps-to">
        <span className="span">Two easy steps to get your&nbsp;&nbsp;</span>
        <span className="text-wrapper-3">discount</span>
      </h1>
      <div className="group-5">
        <img
          className="group-4"
          alt="Group"
          src="https://generation-sessions.s3.amazonaws.com/61ec5fbb8e0d78defedd7b9fd5a4be41/img/group-3762@2x.png"
        />
        <div className="text-wrapper-2">Create a coreflux account.</div>
      </div>
      <div className="group-3">
        <img
          className="group-4"
          alt="Group"
          src="https://generation-sessions.s3.amazonaws.com/61ec5fbb8e0d78defedd7b9fd5a4be41/img/group-3762-1@2x.png"
        />
        <p className="p">Check your email and use it in your purchase.</p>
      </div>
      
      
    </div>
    <div className="coreflux-hub-wrapper">
      <CorefluxHub
        className="coreflux-hub-instance"
        corefluxHubClassName="design-component-instance-node"
        property1="default"
        text="Get Discount"
        userName={props.userName}
      />
    </div>
  </div>
      </div>
      <div className="frame">
        <img
          className="img"
          alt="Group"
          src="https://generation-sessions.s3.amazonaws.com/61ec5fbb8e0d78defedd7b9fd5a4be41/img/group@2x.png"
        />
        <div className="discover-your">Discover Your Special Discount!</div>
      </div>
    </div>
  );
};
