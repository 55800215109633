// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export function Group106Icon(props) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 19 34"}
      height={"1em"}
      width={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M8.65 7.195h.904a9.234 9.234 0 017.61 4.752c.067.136.302.068.235-.102C15.488 4.854 9.454 0 4.056 0 1.643 0 0 1.833 0 4.107s1.81 4.107 4.056 4.107h.37c.234 0 .536-.102.603-.136 1.106-.51 2.313-.849 3.62-.883z"
        }
        fill={"currentColor"}
      ></path>

      <path
        d={
          "M12.937 22.061c.034-.136.034-.272.034-.407.1-2.376-1.71-4.379-4.057-4.48-2.648-.102-4.693-2.376-4.56-5.058 0-.17.034-.34.034-.509 0-.068.034-.102.034-.17.033-.101.033-.237.067-.339a.26.26 0 01.067-.17l.1-.305c.034-.068.034-.102.068-.17.033-.102.1-.204.134-.271.033-.068.033-.102.067-.17l.2-.306c.034-.033.034-.067.068-.101.1-.136.168-.272.302-.374l.067-.068c.067-.101.167-.17.234-.271a.463.463 0 01.135-.102c.067-.068.167-.136.234-.204l.067-.067c-3.05 1.018-5.397 3.733-5.9 7.06-.034.237-.067.508-.1.814 0 .475 0 .95.033 1.425.402 4.209 3.721 7.57 7.878 7.942.302.034.604.034.872.034 2.011-.17 3.62-1.697 3.922-3.733z"
        }
        fill={"currentColor"}
      ></path>

      <path
        d={
          "M18 17.312c.202-4.718-3.285-8.757-7.911-9.198-.134 0-.302-.034-.436-.034h-.402c-2.045.102-3.688 1.663-3.99 3.7-.033.135-.033.271-.033.407-.1 2.376 1.71 4.378 4.056 4.48 2.649.102 4.694 2.376 4.56 5.057 0 .17-.034.34-.067.51 0 .067-.034.101-.034.17-.034.1-.034.237-.067.338 0 .068-.034.136-.034.17l-.1.306c-.034.067-.034.102-.067.17-.034.101-.1.203-.134.305-.034.034-.034.102-.067.136-.067.135-.134.237-.201.373 0 .034-.034.034-.034.068a3.59 3.59 0 01-.302.373l-.1.102a.789.789 0 01-.235.238c-.033.034-.1.067-.134.135-.067.068-.168.136-.235.204l-.067.068c.034 0 .067-.034.1-.034 3.454-1.222 5.767-4.379 5.935-8.044z"
        }
        fill={"currentColor"}
      ></path>

      <path
        d={
          "M9.388 26.641h-.905a9.234 9.234 0 01-7.61-4.751c-.067-.136-.302-.068-.235.101 1.945 6.958 7.945 11.846 13.343 11.846 2.414 0 4.056-1.833 4.056-4.107s-1.81-4.107-4.056-4.107h-.369c-.235 0-.536.102-.603.136-1.107.509-2.314.848-3.62.882z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default Group106Icon;
/* prettier-ignore-end */
