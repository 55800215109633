// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export function Hair3Icon(props) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 32 19"}
      height={"1em"}
      width={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M27.853 12.42c-.44-.797.12-1.61 1.076-2.615C30.444 8.21 32.093 4.507 30.56.75c.01-.021-.302-.654-.292-.675L29.64.042C29.439.014 22.516 0 15.587 0 8.657 0 1.736.014 1.535.042c0 0-.932.686-.924.707-1.53 3.758.116 7.46 1.63 9.056.954 1.005 1.512 1.818 1.077 2.616-.426.777-1.691.89-1.691.89s.291.794.994 1.223c.65.397 1.452.488 2.004.502 0 0 2.162 2.992 7.816 2.992h6.293c5.653 0 7.815-2.992 7.815-2.992.552-.014 1.353-.105 2.004-.502.703-.43.995-1.224.995-1.224s-1.27-.116-1.695-.89z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default Hair3Icon;
/* prettier-ignore-end */
