// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export function Peepers125Icon(props) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 2 2"}
      height={"1em"}
      width={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M1.47.14C1.109-.115.565-.034.213.472-.139.982-.023 1.516.34 1.77c.362.253.907.172 1.258-.334.355-.51.24-1.045-.126-1.298z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default Peepers125Icon;
/* prettier-ignore-end */
