// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export function Group27Icon(props) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 11 6"}
      height={"1em"}
      width={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M9.645 5.34a.39.39 0 01-.376-.292c-.06-.102-.59-.847-4.208-2.672C1.587.626.687.756.58.784a.384.384 0 01-.484-.08.385.385 0 01.035-.546C.438-.109 1.53-.27 5.409 1.688c2.109 1.061 4.62 2.492 4.62 3.269a.38.38 0 01-.384.383zm-9-4.605c0 .003-.004.003 0 0z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default Group27Icon;
/* prettier-ignore-end */
