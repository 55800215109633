// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export function Vector19Icon(props) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 9 4"}
      height={"1em"}
      width={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M.113.731s-.33-.524.137-.7c.468-.175 2.37.43 3.885.777 1.515.348 4.409 1.108 4.409 1.108l-.099.7S2.968 3.683 2.93 3.623c-.038-.06-.622-.738-.66-.854C2.23 2.653 1.007 1.12.948 1.12.891 1.12.114.73.114.73z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default Vector19Icon;
/* prettier-ignore-end */
