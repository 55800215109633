// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export function Mouth2Icon(props) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 9 3"}
      height={"1em"}
      width={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M7.004.122c-1.117.665-4.791.665-5.91 0C.452-.26-.205.326.062.91c.264.573 2.264 1.905 3.997 1.905 1.734 0 3.71-1.332 3.973-1.905.264-.584-.383-1.17-1.027-.788z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default Mouth2Icon;
/* prettier-ignore-end */
