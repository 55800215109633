// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export function Group100Icon(props) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 16 32"}
      height={"1em"}
      width={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M7.356 6.593h.77c2.795.155 5.19 1.866 6.473 4.354a.11.11 0 00.2-.094C13.172 4.447 8.04 0 3.45 0 1.397 0 0 1.68 0 3.763c0 2.084 1.54 3.763 3.45 3.763h.314c.2 0 .456-.093.513-.125.94-.466 1.967-.777 3.08-.808z"
        }
        fill={"currentColor"}
      ></path>

      <path
        d={
          "M11.003 20.214c.029-.125.029-.25.029-.373.085-2.177-1.455-4.012-3.45-4.106-2.253-.093-3.992-2.176-3.878-4.633 0-.156.028-.311.028-.467 0-.062.029-.093.029-.155.028-.094.028-.218.057-.311a.25.25 0 01.057-.156l.085-.28c.029-.062.029-.093.057-.155.029-.093.086-.187.114-.249.029-.062.029-.093.057-.155l.171-.28c.029-.031.029-.062.058-.093.085-.125.142-.25.256-.343.029-.03.057-.062.057-.062.057-.093.143-.155.2-.249a.4.4 0 01.114-.093c.057-.062.142-.124.2-.186l.056-.063C2.706 8.738.71 11.226.282 14.274c-.028.218-.057.466-.085.746 0 .436 0 .871.028 1.306.342 3.857 3.165 6.935 6.7 7.277.257.031.514.031.742.031 1.71-.155 3.08-1.554 3.336-3.42z"
        }
        fill={"currentColor"}
      ></path>

      <path
        d={
          "M15.31 15.862c.171-4.323-2.794-8.023-6.73-8.428-.113 0-.256-.03-.37-.03h-.342c-1.74.093-3.137 1.523-3.393 3.389-.029.124-.029.249-.029.373-.085 2.177 1.455 4.012 3.45 4.105 2.253.093 3.992 2.177 3.878 4.634 0 .155-.028.31-.057.466 0 .063-.028.094-.028.156-.029.093-.029.217-.057.31 0 .063-.029.125-.029.156l-.085.28c-.029.062-.029.093-.057.156-.029.093-.086.186-.114.28-.029.03-.029.093-.057.124-.057.124-.114.218-.172.342 0 .031-.028.031-.028.062a3.264 3.264 0 01-.257.342l-.085.094a.7.7 0 01-.2.217c-.028.031-.085.063-.114.125-.057.062-.142.124-.2.186l-.056.063c.028 0 .056-.032.085-.032 2.937-1.12 4.904-4.011 5.047-7.37z"
        }
        fill={"currentColor"}
      ></path>

      <path
        d={
          "M7.985 24.41h-.77c-2.794-.155-5.19-1.866-6.472-4.354a.11.11 0 00-.2.094c1.654 6.375 6.758 10.853 11.348 10.853 2.053 0 3.45-1.68 3.45-3.763 0-2.084-1.54-3.763-3.45-3.763h-.313a1.4 1.4 0 00-.514.125c-.94.466-1.967.777-3.08.808z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default Group100Icon;
/* prettier-ignore-end */
