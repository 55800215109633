// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export function Vector11Icon(props) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 41 39"}
      height={"1em"}
      width={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        opacity={".5"}
        d={
          "M21.09 2.275l.426 2.264 3.656 2.278-3.94-.626-2.806-1.53-2.788-2.355s-3.899 6.69-5.214 9.65c-2.714 6.107-1.694 9.911-1.62 12.288.052 1.62-.49 2.601-.83 3.315-.338.717-2.345 4.94-2.345 4.94s-1.905-1.052-3.84-.974c-1.655.063-1.82 1.1-1.785 1.364.039.263 2.352 3.304 5.97 4.964 3.617 1.66 5.424.71 5.424.71s1.023-.587-.221-2.134c-1.245-1.547-2.94-2.5-2.94-2.5l3.052-6.669 1.997-2.11 2.26-1.659 2.261-2.489 2.074-3.202 2.525-4.824.566-1.055s1.357 4.785 3.051 7.5c1.695 2.713 2.637 2.938 3.618 4.935.98 1.997 2.31 7.756 2.31 7.756s-1.463.474-2.83 1.269c-1.98 1.15-1.372 2.074-1.312 2.538.12.935 2.96 1.667 7.597.046 4.637-1.62 5.042-2.97 4.926-3.65-.113-.678-1.484-.927-2.676-1.005-1.082-.07-2.78.218-2.78.218l-1.206-3.853-.679-3.544.264-2.601.678-2.714.264-3.73-.605-5.277-1.694-7.612L31.216 0s-1.554 1.462-4.567 2.04c-3.386.653-5.558.235-5.558.235z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default Vector11Icon;
/* prettier-ignore-end */
