// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export function HairIcon(props) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 31 22"}
      height={"1em"}
      width={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M27.743 6.051c-.278-1.66-4.87-4.612-6.588-5.157C16.5-.58 13.472.018 11.848.904c-.338.183-2.521 1.396-4.047.527C6.846.887 4.1 5.242 2.72 6.076 1.105 7.049 1.246 8.82 1.492 9.51c-.886.753-2 2.352-1.238 4.43.577 1.565 2.872 2.285 2.872 2.285-.218 3.776.802 5.608.802 5.608s1.649-6.487 1.512-7.478c0 0 2.728-.542 5.825-2.313 2.092-1.2 3.523-2.602 5.875-3.133 3.579-.805 4.377 1.786 4.377 1.786s3.311-.636 4.31 3.962c.411 1.892.678 5.028.903 6.95-.02-.168 1.14-2.225 1.357-4.334.057-.545 1.526-1.266 2.159-3.607.844-3.122-.084-6.504-2.503-7.615z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default HairIcon;
/* prettier-ignore-end */
