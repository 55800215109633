// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export function Head1Icon(props) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 24 30"}
      height={"1em"}
      width={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M11.784 0C5.667 0 0 6.543 0 15.96c0 9.367 5.84 13.997 11.784 13.997 5.945 0 11.785-4.63 11.785-13.996C23.569 6.543 17.902 0 11.784 0z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default Head1Icon;
/* prettier-ignore-end */
