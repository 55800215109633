// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PlasmicBtnSelect_group9__HWIR3 {
  transform: translate(-0.4049999999999727px, 0px);
  width: 183px;
  height: 46px;
  display: block;
  position: relative;
}
.PlasmicBtnSelect_searchTop__s9moo {
  width: 183px;
  height: 46px;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;
}
.PlasmicBtnSelect_rectangle2__mzJqm {
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  top: 0%;
  left: 0%;
  background: #ffffff2b;
  border-radius: 50px;
}
.PlasmicBtnSelect_text__1j6Ug {
  display: block;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  letter-spacing: 0em;
  line-height: 100%;
  color: rgba(255, 255, 255, 1);
  position: absolute;
  top: 17px;
  left: 27.405029296875px;
  width: 57.14%;
  transform: translate(0.09500000000002728px, 0px);
}
.PlasmicBtnSelect_svg__lB5Dl {
  position: absolute;
  object-fit: cover;
  max-width: 100%;
  color: #fff;
  transform: translate(0.1449999999999818px, -0.5px);
  width: 13px;
  height: 7px;
  display: block;
  top: 47.82%;
  left: 79.08%;
}
`, "",{"version":3,"sources":["webpack://./src/components/plasmic/copy_of_blank_project/PlasmicBtnSelect.module.css"],"names":[],"mappings":"AAAA;EACE,gDAAgD;EAChD,YAAY;EACZ,YAAY;EACZ,cAAc;EACd,kBAAkB;AACpB;AACA;EACE,YAAY;EACZ,YAAY;EACZ,cAAc;EACd,kBAAkB;EAClB,QAAQ;EACR,SAAS;AACX;AACA;EACE,WAAW;EACX,YAAY;EACZ,cAAc;EACd,kBAAkB;EAClB,OAAO;EACP,QAAQ;EACR,qBAAqB;EACrB,mBAAmB;AACrB;AACA;EACE,cAAc;EACd,eAAe;EACf,iCAAiC;EACjC,gBAAgB;EAChB,mBAAmB;EACnB,iBAAiB;EACjB,6BAA6B;EAC7B,kBAAkB;EAClB,SAAS;EACT,uBAAuB;EACvB,aAAa;EACb,gDAAgD;AAClD;AACA;EACE,kBAAkB;EAClB,iBAAiB;EACjB,eAAe;EACf,WAAW;EACX,kDAAkD;EAClD,WAAW;EACX,WAAW;EACX,cAAc;EACd,WAAW;EACX,YAAY;AACd","sourcesContent":[".group9 {\n  transform: translate(-0.4049999999999727px, 0px);\n  width: 183px;\n  height: 46px;\n  display: block;\n  position: relative;\n}\n.searchTop {\n  width: 183px;\n  height: 46px;\n  display: block;\n  position: absolute;\n  top: 0px;\n  left: 0px;\n}\n.rectangle2 {\n  width: 100%;\n  height: 100%;\n  display: block;\n  position: absolute;\n  top: 0%;\n  left: 0%;\n  background: #ffffff2b;\n  border-radius: 50px;\n}\n.text {\n  display: block;\n  font-size: 14px;\n  font-family: \"Roboto\", sans-serif;\n  font-weight: 500;\n  letter-spacing: 0em;\n  line-height: 100%;\n  color: rgba(255, 255, 255, 1);\n  position: absolute;\n  top: 17px;\n  left: 27.405029296875px;\n  width: 57.14%;\n  transform: translate(0.09500000000002728px, 0px);\n}\n.svg {\n  position: absolute;\n  object-fit: cover;\n  max-width: 100%;\n  color: #fff;\n  transform: translate(0.1449999999999818px, -0.5px);\n  width: 13px;\n  height: 7px;\n  display: block;\n  top: 47.82%;\n  left: 79.08%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"group9": `PlasmicBtnSelect_group9__HWIR3`,
	"searchTop": `PlasmicBtnSelect_searchTop__s9moo`,
	"rectangle2": `PlasmicBtnSelect_rectangle2__mzJqm`,
	"text": `PlasmicBtnSelect_text__1j6Ug`,
	"svg": `PlasmicBtnSelect_svg__lB5Dl`
};
export default ___CSS_LOADER_EXPORT___;
