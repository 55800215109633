// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export function Group96Icon(props) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 21 39"}
      height={"1em"}
      width={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M10.002 8.293h1.047a10.685 10.685 0 018.8 5.477c.077.157.349.079.271-.117C17.91 5.594 10.932 0 4.69 0 1.9 0 0 2.112 0 4.734c0 2.62 2.093 4.733 4.69 4.733h.427c.272 0 .62-.117.698-.156 1.28-.587 2.675-.978 4.187-1.018z"
        }
        fill={"currentColor"}
      ></path>

      <path
        d={
          "M14.96 25.428c.04-.156.04-.313.04-.47.116-2.738-1.978-5.046-4.691-5.163-3.063-.117-5.428-2.738-5.273-5.829 0-.195.039-.391.039-.587 0-.078.039-.117.039-.195.038-.118.038-.274.077-.391a.3.3 0 01.078-.196l.116-.352c.039-.078.039-.118.078-.196.038-.117.116-.234.155-.313.038-.078.038-.117.077-.195l.233-.352c.039-.04.039-.079.077-.118.117-.156.194-.313.35-.43.038-.04.077-.078.077-.078.077-.118.194-.196.271-.313a.538.538 0 01.155-.118c.078-.078.194-.156.272-.234l.077-.079C3.68 10.993.966 14.123.384 17.956a30.92 30.92 0 00-.116.94c0 .547 0 1.095.039 1.642.465 4.851 4.303 8.724 9.11 9.154.349.04.698.04 1.008.04 2.326-.196 4.187-1.957 4.535-4.304z"
        }
        fill={"currentColor"}
      ></path>

      <path
        d={
          "M20.816 19.954c.233-5.438-3.8-10.093-9.149-10.602-.155 0-.349-.039-.504-.039h-.465c-2.365.117-4.265 1.917-4.613 4.264-.04.157-.04.313-.04.47-.116 2.738 1.978 5.046 4.691 5.163 3.063.118 5.428 2.739 5.273 5.83 0 .195-.039.39-.078.586 0 .078-.038.117-.038.196-.04.117-.04.274-.078.39 0 .08-.039.157-.039.197l-.116.352c-.039.078-.039.117-.078.195-.038.118-.116.235-.155.352-.038.04-.038.118-.077.157-.078.156-.155.274-.233.43 0 .04-.039.04-.039.078a4.051 4.051 0 01-.348.43l-.117.118a.908.908 0 01-.271.274c-.039.039-.116.078-.155.156-.078.079-.194.157-.272.235-.038.04-.038.04-.077.078.039 0 .077-.039.116-.039 3.993-1.408 6.668-5.046 6.862-9.271z"
        }
        fill={"currentColor"}
      ></path>

      <path
        d={
          "M10.857 30.706H9.81a10.682 10.682 0 01-8.8-5.476c-.077-.157-.349-.079-.271.117C2.987 33.367 9.926 39 16.168 39c2.791 0 4.69-2.112 4.69-4.733 0-2.622-2.093-4.734-4.69-4.734h-.427c-.27 0-.62.117-.697.156-1.28.587-2.675.978-4.187 1.017z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default Group96Icon;
/* prettier-ignore-end */
