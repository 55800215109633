// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PlasmicCoffeEmoji_root__ZXz\\+G {
  object-fit: cover;
  max-width: 100%;
  width: 50px;
  height: 50px;
  display: block !important;
  overflow: hidden;
  position: relative;
}
.PlasmicCoffeEmoji_root__ZXz\\+G > picture > img {
  object-fit: cover;
}
.PlasmicCoffeEmoji_root__ZXz\\+G > .__wab_img-spacer > img {
  object-fit: cover;
}
`, "",{"version":3,"sources":["webpack://./src/components/plasmic/copy_of_blank_project/PlasmicCoffeEmoji.module.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,eAAe;EACf,WAAW;EACX,YAAY;EACZ,yBAAyB;EACzB,gBAAgB;EAChB,kBAAkB;AACpB;AACA;EACE,iBAAiB;AACnB;AACA;EACE,iBAAiB;AACnB","sourcesContent":[".root {\n  object-fit: cover;\n  max-width: 100%;\n  width: 50px;\n  height: 50px;\n  display: block !important;\n  overflow: hidden;\n  position: relative;\n}\n.root > picture > img {\n  object-fit: cover;\n}\n.root > :global(.__wab_img-spacer) > img {\n  object-fit: cover;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `PlasmicCoffeEmoji_root__ZXz+G`
};
export default ___CSS_LOADER_EXPORT___;
