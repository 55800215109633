// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export function Vector7Icon(props) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 12 4"}
      height={"1em"}
      width={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M11.725 2.243c-.13-.12-.264-.229-.398-.341C9.868.682 8.272 0 6.124 0 3.984 0 2.201.633.69 1.835c-.137.11-.274.215-.408.33-.109.124-.2.24-.281.352 1.832.728 3.878 1.125 6.071 1.132h.057c2.095-.007 4.057-.369 5.825-1.037a3.423 3.423 0 00-.228-.369z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default Vector7Icon;
/* prettier-ignore-end */
