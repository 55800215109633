// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export function Group19Icon(props) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 28 5"}
      height={"1em"}
      width={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M24.88.118c-3.15.985-7.196 1.554-11.106 1.558-3.91-.004-7.956-.573-11.106-1.558-1.05-.327-2.042.05-2.51.914A1.757 1.757 0 000 1.454c.116.06.25.12.408.183 4.254 2.17 8.845 3.14 13.64 3.14 4.602 0 9.014-.897 13.124-2.887.165-.07.303-.137.426-.2a1.918 1.918 0 00-.211-.658C26.923.168 25.935-.212 24.88.118z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default Group19Icon;
/* prettier-ignore-end */
