// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export function Eyebrows2Icon(props) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 17 2"}
      height={"1em"}
      width={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M5.18 1.058C4.853.626 4.1 0 2.638 0 1.175 0 .42.63.096 1.058a.447.447 0 00-.007.545c.091.123.366.24.668.137.302-.102.893-.408 1.88-.415.989.007 1.58.313 1.882.415.302.102.576-.01.668-.137a.452.452 0 00-.007-.545zm11.556 0C16.409.626 15.656 0 14.194 0c-1.463 0-2.219.63-2.542 1.058a.447.447 0 00-.007.545c.091.123.366.24.668.137.302-.102.893-.408 1.88-.415.989.007 1.58.313 1.882.415.302.102.576-.01.668-.137a.452.452 0 00-.007-.545z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default Eyebrows2Icon;
/* prettier-ignore-end */
