// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export function Eyes2Icon(props) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 16 4"}
      height={"1em"}
      width={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M1.733 3.586c.957 0 1.733-.803 1.733-1.793C3.466.803 2.69 0 1.733 0 .776 0 0 .803 0 1.793c0 .99.776 1.793 1.733 1.793zm11.56 0c.957 0 1.733-.803 1.733-1.793C15.026.803 14.25 0 13.293 0c-.958 0-1.734.803-1.734 1.793 0 .99.776 1.793 1.734 1.793z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default Eyes2Icon;
/* prettier-ignore-end */
