// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export function LeftIcon12Icon(props) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 12 12"}
      height={"1em"}
      width={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M3.885 3.4V1.456M7.77 3.399V1.457M3.4 5.342h4.856m-5.828 4.856h6.799a.971.971 0 00.971-.971V3.399a.971.971 0 00-.971-.97H2.428a.971.971 0 00-.971.97v5.828a.971.971 0 00.971.971z"
        }
        stroke={"currentColor"}
        strokeWidth={"1.214"}
        strokeLinecap={"round"}
        strokeLinejoin={"round"}
      ></path>
    </svg>
  );
}

export default LeftIcon12Icon;
/* prettier-ignore-end */
