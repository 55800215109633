// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export function Vector64Icon(props) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 6 10"}
      height={"1em"}
      width={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M5.028 4.352c.013.155.927.23.93.38.046 2.297-1.06 4.187-2.592 4.317C1.734 9.19.239 7.277.026 4.781-.189 2.284.961.146 2.591.007 4.082-.12 5.458 1.462 5.85 3.64c.036.207-.84.497-.822.713z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default Vector64Icon;
/* prettier-ignore-end */
