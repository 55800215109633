// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.component {
  height: 40px;
  left: 0;
  position: absolute;
  top: 0;
  width: 43px;
}

.component .property-style {
  height: 40px !important;
  left: 0 !important;
  position: absolute !important;
  top: 0 !important;
  width: 40px !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/Component/style.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,OAAO;EACP,kBAAkB;EAClB,MAAM;EACN,WAAW;AACb;;AAEA;EACE,uBAAuB;EACvB,kBAAkB;EAClB,6BAA6B;EAC7B,iBAAiB;EACjB,sBAAsB;AACxB","sourcesContent":[".component {\n  height: 40px;\n  left: 0;\n  position: absolute;\n  top: 0;\n  width: 43px;\n}\n\n.component .property-style {\n  height: 40px !important;\n  left: 0 !important;\n  position: absolute !important;\n  top: 0 !important;\n  width: 40px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
