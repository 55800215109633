import React from "react";
import "./menuCodeFestStyle.css";
import corefluxImg from "../../img/group.png";
import close from "../../img/group-3729.png";
import { Link } from "react-router-dom";

export default function CodeFestMenu(props) {
  const handleClick = () => {
    const menu = document.getElementById("menu")
    const menuWrapper = document.getElementById("menuWrapper")

    if(menu.style.width === "0px"){
      menuWrapper.style.width = "100%"
      menu.style.width = "100%"
      document.body.style.overflow = 'hidden'
    }else{
      menu.style.width = "0px"
      menuWrapper.style.width = "0px"
      document.body.style.overflow = 'auto'
    }

  }


  return (
    <div className="CODE-FEST-MENU" id="menuWrapper">
      <div className="div" id="menu">
        <div className="overlap-group">
          <div className="group">
            <img className="img" alt="Group" src={corefluxImg} />
            <div className="group-wrapper">
              <img className="group-2" alt="Group" src={close} onClick={handleClick}/>
            </div>
          </div>
          <div className="group-3">
            <a className="text-wrapper" href="/#aboutMobile" onClick={handleClick}>ABOUT</a>
            <a className="text-wrapper-2" href="https://www.coreflux.org/codefest/porto2023/CorefluxCodefest_Porto2023.pdf" target="_blank" onClick={handleClick}>PROGRAM</a>
            <a className="text-wrapper-3" href="/#peopleMobile" onClick={handleClick}>PEOPLE</a>
            <Link className="text-wrapper-4" to="/pricing" state={{userLogged: props.userLogged, userName: props.userName}} onClick={handleClick}>PRICING</Link>
          </div>
        </div>
      </div>
    </div>
  );
};
