// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export function LeftIcon7Icon(props) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 11 11"}
      height={"1em"}
      width={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        fillRule={"evenodd"}
        clipRule={"evenodd"}
        d={
          "M5.1 9.178a4.08 4.08 0 100-8.158 4.08 4.08 0 000 8.158zM6.99 4.44a.51.51 0 00-.722-.721L4.59 5.398l-.66-.66a.51.51 0 00-.72.722l1.02 1.02a.51.51 0 00.72 0l2.04-2.04z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default LeftIcon7Icon;
/* prettier-ignore-end */
