// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export function Group10Icon(props) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 55 56"}
      height={"1em"}
      width={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M52.81 1.95h-3.254c-7.02.343-13.7 1.884-20.035 4.967-7.706 3.596-14.213 8.733-19.35 15.583-3.597 4.623-6.165 9.76-8.049 15.24-.171.343 0 .514.171.685l4.624 4.624c.171.171.342.514.342.856a.942.942 0 01-.513.856c-.343.171-.857.171-1.028-.171l-2.397-2.397C2.293 41.165 1.437 40.138.41 39.282c-.343-.343-.514-.857-.343-1.37 2.226-6.679 5.48-12.844 10.103-18.323 4.624-5.651 10.275-10.104 16.61-13.357C32.604 3.15 38.94 1.266 45.447.41c2.74-.343 5.48-.514 8.22-.343.856 0 1.027.343 1.199 1.199.342 11.13-2.398 21.405-8.391 30.995C40.48 41.679 32.26 48.7 21.986 53.152c-1.541.685-3.253 1.199-4.966 1.884-.514.171-.856.171-1.199-.343l-5.308-5.308c-.343-.343-.514-.856-.171-1.37.342-.514 1.027-.514 1.54 0l4.624 4.623c.343.343.514.343.857.172a52.808 52.808 0 0019.864-12.16c6.678-6.507 11.13-14.213 13.699-22.946 1.37-4.795 2.055-9.76 2.055-14.898 0-.342-.171-.514-.171-.856z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default Group10Icon;
/* prettier-ignore-end */
