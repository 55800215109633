// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export function Vector8Icon(props) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 28 6"}
      height={"1em"}
      width={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M13.854 2.842c2.873.05 5.21-.386 8.051-.928.71-.137 1.417-.295 2.116-.481l.524-.144a2.17 2.17 0 01.419-.081c.267-.025.527.014.752.109.447.186 1.083.749 1.241 1.199.418 1.195-.26 3.283-.091 2.935.007-.01.432-.728.436-.738.284-.686.657-1.463.566-2.401-.095-.946-.809-1.814-1.698-2.141a2.764 2.764 0 00-1.343-.145 3.226 3.226 0 00-.623.148l-.495.162c-.665.204-1.34.387-2.022.548-2.728.637-4.978.97-7.798 1.04h-.084c-2.82-.066-4.932-.403-7.66-1.04A32.275 32.275 0 014.123.336L3.627.174a3.29 3.29 0 00-.622-.148 2.76 2.76 0 00-1.343.145C.772.498.147 1.362.02 2.305-.184 3.845 1.212 5.5 1.22 5.51c.168.348-.707-1.807-.29-3.002.16-.45.74-1.006 1.182-1.192.225-.095.482-.137.752-.11.141.011.257.04.419.082l.524.144c.703.186 1.41.344 2.116.481 2.837.538 4.978.974 7.85.928"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default Vector8Icon;
/* prettier-ignore-end */
