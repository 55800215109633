// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export function Vector72Icon(props) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 2 2"}
      height={"1em"}
      width={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M.868.498c.004.02.162.007.164.026.035.288-.134.554-.398.608C.354 1.19.074.986.013.675-.05.365.124.067.4.01c.253-.053.507.114.6.379.01.025-.14.081-.132.11z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default Vector72Icon;
/* prettier-ignore-end */
