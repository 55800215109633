// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PlasmicRegisterBtn_group3678__Yt1m8 {
  width: 546px;
  height: 58px;
  display: block;
  position: relative;
}
.PlasmicRegisterBtn_link__pNXr\\+ {
  background: linear-gradient(rgba(17, 29, 51, 0.059), rgba(17, 29, 51, 0.059)),
    rgba(247, 104, 50, 1);
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
  transition-property: all;
  transition-duration: 0.5s;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  -webkit-transition-property: all;
  -webkit-transition-duration: 0.5s;
  border-radius: 63px;
}
.PlasmicRegisterBtn_group3678__Yt1m8:hover .PlasmicRegisterBtn_link__pNXr\\+ {
  background: linear-gradient(
    0deg,
    var(--token-AKN85WL_zpQQVv_) 0%,
    var(--token-uPJNyvE_KC5PDlj) 100%
  );
  transform: scaleX(1.01) scaleY(1.01) scaleZ(1);
  cursor: pointer;
}
.PlasmicRegisterBtn_text__p2-SB {
  display: block;
  font-size: 20px;
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  letter-spacing: 2px;
  line-height: 32px;
  color: rgba(255, 255, 255, 1);
  position: relative;
  top: auto;
  left: auto;
  pointer-events: none;
}
.PlasmicRegisterBtn_group3678__Yt1m8:hover .PlasmicRegisterBtn_text__p2-SB {
  color: var(--token-yNXqM_2BF0dPEPK);
}
`, "",{"version":3,"sources":["webpack://./src/components/plasmic/copy_of_blank_project/PlasmicRegisterBtn.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,YAAY;EACZ,cAAc;EACd,kBAAkB;AACpB;AACA;EACE;yBACuB;EACvB,WAAW;EACX,YAAY;EACZ,aAAa;EACb,gBAAgB;EAChB,wBAAwB;EACxB,yBAAyB;EACzB,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,gCAAgC;EAChC,iCAAiC;EACjC,mBAAmB;AACrB;AACA;EACE;;;;GAIC;EACD,8CAA8C;EAC9C,eAAe;AACjB;AACA;EACE,cAAc;EACd,eAAe;EACf,kBAAkB;EAClB,iCAAiC;EACjC,gBAAgB;EAChB,mBAAmB;EACnB,iBAAiB;EACjB,6BAA6B;EAC7B,kBAAkB;EAClB,SAAS;EACT,UAAU;EACV,oBAAoB;AACtB;AACA;EACE,mCAAmC;AACrC","sourcesContent":[".group3678 {\n  width: 546px;\n  height: 58px;\n  display: block;\n  position: relative;\n}\n.link {\n  background: linear-gradient(rgba(17, 29, 51, 0.059), rgba(17, 29, 51, 0.059)),\n    rgba(247, 104, 50, 1);\n  width: 100%;\n  height: 100%;\n  display: flex;\n  overflow: hidden;\n  transition-property: all;\n  transition-duration: 0.5s;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  -webkit-transition-property: all;\n  -webkit-transition-duration: 0.5s;\n  border-radius: 63px;\n}\n.group3678:hover .link {\n  background: linear-gradient(\n    0deg,\n    var(--token-AKN85WL_zpQQVv_) 0%,\n    var(--token-uPJNyvE_KC5PDlj) 100%\n  );\n  transform: scaleX(1.01) scaleY(1.01) scaleZ(1);\n  cursor: pointer;\n}\n.text {\n  display: block;\n  font-size: 20px;\n  text-align: center;\n  font-family: \"Roboto\", sans-serif;\n  font-weight: 500;\n  letter-spacing: 2px;\n  line-height: 32px;\n  color: rgba(255, 255, 255, 1);\n  position: relative;\n  top: auto;\n  left: auto;\n  pointer-events: none;\n}\n.group3678:hover .text {\n  color: var(--token-yNXqM_2BF0dPEPK);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"group3678": `PlasmicRegisterBtn_group3678__Yt1m8`,
	"link": `PlasmicRegisterBtn_link__pNXr+`,
	"text": `PlasmicRegisterBtn_text__p2-SB`
};
export default ___CSS_LOADER_EXPORT___;
