// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.coreflux-hub {
  align-items: center;
  background-color: #f1f7f9;
  border-radius: 62px;
  display: flex;
  gap: 10px;
  justify-content: center;
  padding: 18px 35px;
  position: relative;
  width: -moz-fit-content;
  width: fit-content;
  border: none;
  cursor: pointer;
}

.coreflux-hub:hover {
  background: linear-gradient(
    0deg,
    var(--token-AKN85WL_zpQQVv_) 0%,
    var(--token-uPJNyvE_KC5PDlj) 100%
  );
  cursor: pointer;
  transform: scaleX(1.01) scaleY(1.01) scaleZ(1.01);
}

.coreflux-hub .text-wrapper {
  color: #000000;
  font-family: "Roboto", Helvetica;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 20px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
}
`, "",{"version":3,"sources":["webpack://./src/components/CorefluxHub/style.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,yBAAyB;EACzB,mBAAmB;EACnB,aAAa;EACb,SAAS;EACT,uBAAuB;EACvB,kBAAkB;EAClB,kBAAkB;EAClB,uBAAkB;EAAlB,kBAAkB;EAClB,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE;;;;GAIC;EACD,eAAe;EACf,iDAAiD;AACnD;;AAEA;EACE,cAAc;EACd,gCAAgC;EAChC,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,iBAAiB;EACjB,gBAAgB;EAChB,kBAAkB;EAClB,mBAAmB;EACnB,uBAAkB;EAAlB,kBAAkB;AACpB","sourcesContent":[".coreflux-hub {\n  align-items: center;\n  background-color: #f1f7f9;\n  border-radius: 62px;\n  display: flex;\n  gap: 10px;\n  justify-content: center;\n  padding: 18px 35px;\n  position: relative;\n  width: fit-content;\n  border: none;\n  cursor: pointer;\n}\n\n.coreflux-hub:hover {\n  background: linear-gradient(\n    0deg,\n    var(--token-AKN85WL_zpQQVv_) 0%,\n    var(--token-uPJNyvE_KC5PDlj) 100%\n  );\n  cursor: pointer;\n  transform: scaleX(1.01) scaleY(1.01) scaleZ(1.01);\n}\n\n.coreflux-hub .text-wrapper {\n  color: #000000;\n  font-family: \"Roboto\", Helvetica;\n  font-size: 16px;\n  font-weight: 500;\n  letter-spacing: 0;\n  line-height: 20px;\n  margin-top: -1px;\n  position: relative;\n  white-space: nowrap;\n  width: fit-content;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
