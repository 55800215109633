// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export function Vector66Icon(props) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 8 4"}
      height={"1em"}
      width={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M7.062 3.744a.214.214 0 01-.217-.134C6.79 3.47 5.426.19.223.44A.212.212 0 010 .236.212.212 0 01.202.013C5.724-.249 7.226 3.418 7.24 3.454a.212.212 0 01-.18.29h.002z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default Vector66Icon;
/* prettier-ignore-end */
