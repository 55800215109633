// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export function Vector69Icon(props) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 2 2"}
      height={"1em"}
      width={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M.873.576c0 .02.156.042.154.06-.028.29-.253.514-.524.509C.215 1.142-.009.875 0 .559.01.24.245-.003.527 0c.258.003.467.22.5.498C1.03.525.874.55.873.578V.576z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default Vector69Icon;
/* prettier-ignore-end */
