// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export function Vector29Icon(props) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 14 6"}
      height={"1em"}
      width={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M6.97 4.108c2.033-.495 3.316-1.026 4.135-1.596C12.153 1.781 12.42 1.04 12.409.8c-.02-.422-.218-.72-.19-.78.064-.127.809.38.844.843.028.36.155 1.238-1.494 2.29-.868.555-2.141 1.209-4.363 1.757-2.183.538-3.94.65-5.164.425C.74 5.096.045 4.488.002 3.803-.035 3.17.366 2.737.425 2.75c.085.021-.15.464.085.907.186.345.65.679 1.56.858.954.19 2.402.204 4.902-.408z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default Vector29Icon;
/* prettier-ignore-end */
