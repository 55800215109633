// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export function Vector34Icon(props) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 13 8"}
      height={"1em"}
      width={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M5.28 5.242c1.855.967 3.17 1.414 4.158 1.526 1.273.14 1.765.021 2.043-.221.316-.278.31-.686.372-.71.13-.053.355.819.078 1.188-.215.288-.637.826-2.57.527-1.02-.154-2.408-.502-4.44-1.554C2.924 4.965 1.528 3.89.758 2.915-.062 1.878-.184.96.234.42c.387-.503.72-.436.76-.383.049.07-.17.123-.282.608-.088.383.043.939.609 1.673.594.767 1.676 1.734 3.958 2.925z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default Vector34Icon;
/* prettier-ignore-end */
