import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Homepage from './components/Homepage';
import Pricing from './components/Pricing';
import Layout from "./pages/layout";
import { initializeApp } from 'firebase/app';
import CodeFestMenu from "./screens/CodeFestMenu/CodeFestMenu";
import CodeFestTerms from "./screens/CodeFestTerms/CodeFestTerms";

function App() {

  const firebaseConfig = {
    apiKey: "AIzaSyAxSQOl8fuL6nStTVI-m7HTnzxAMCXBK2o",
    authDomain: "coreflux-575ac.firebaseapp.com",
    databaseURL: "https://coreflux-575ac.firebaseio.com",
    projectId: "coreflux-575ac",
    storageBucket: "coreflux-575ac.appspot.com",
    messagingSenderId: "1072615036869",
    appId: "1:1072615036869:web:85b7d9374f9713d1"
  };

  const app = initializeApp(firebaseConfig);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Homepage />} />
          <Route path="pricing" element={<Pricing />} />
          <Route path="menu" element={<CodeFestMenu></CodeFestMenu>}></Route>
          <Route path="terms&conditions" element={<CodeFestTerms></CodeFestTerms>}></Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
  