// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export function Nose2Icon(props) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 4 2"}
      height={"1em"}
      width={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M2.995.028A.44.44 0 002.882 0H.393a.406.406 0 00-.112.028C.056.12-.067.352.038.601c.106.25.601.946 1.6.946.998 0 1.494-.7 1.6-.946C3.342.355 3.22.12 2.994.028z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default Nose2Icon;
/* prettier-ignore-end */
