// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export function Group25Icon(props) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 11 4"}
      height={"1em"}
      width={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M2.011 3.194c-1.146 0-1.702-.165-1.74-.18a.387.387 0 01.232-.738c.014.004 1.522.436 4.837-.228C8.968 1.317 9.861.167 9.872.156a.39.39 0 01.538-.084.38.38 0 01.091.53c-.098.142-1.08 1.41-5.01 2.202-1.494.302-2.643.39-3.48.39z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default Group25Icon;
/* prettier-ignore-end */
