// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export function Group37Icon(props) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 30 55"}
      height={"1em"}
      width={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M14.105 11.696h1.476c5.358.276 9.95 3.31 12.41 7.723.11.221.493.11.383-.165C25.258 7.889 15.417 0 6.615 0 2.68 0 0 2.98 0 6.675c0 3.697 2.952 6.676 6.615 6.676h.602c.382 0 .874-.166.984-.22 1.804-.828 3.772-1.38 5.904-1.435z"
        }
        fill={"currentColor"}
      ></path>

      <path
        d={
          "M21.097 35.86c.055-.22.055-.441.055-.662.164-3.862-2.788-7.117-6.615-7.282-4.32-.166-7.654-3.862-7.436-8.22 0-.277.055-.552.055-.828 0-.11.055-.166.055-.276.054-.165.054-.386.11-.552 0-.11.054-.22.108-.276l.164-.496c.055-.11.055-.166.11-.276.054-.165.164-.33.218-.441.055-.11.055-.166.11-.276l.328-.497c.055-.055.055-.11.11-.165.163-.22.273-.441.491-.607l.11-.11c.109-.166.273-.276.382-.442a.755.755 0 01.219-.165c.11-.11.273-.22.383-.331l.109-.11C5.188 15.501 1.361 19.915.541 25.322c-.055.386-.11.827-.164 1.324 0 .772 0 1.544.055 2.317.656 6.84 6.068 12.302 12.847 12.91.492.054.984.054 1.422.054 3.28-.275 5.904-2.758 6.396-6.068z"
        }
        fill={"currentColor"}
      ></path>

      <path
        d={
          "M29.357 28.14c.328-7.67-5.358-14.234-12.903-14.952-.219 0-.492-.055-.71-.055h-.657c-3.335.166-6.013 2.704-6.505 6.014-.055.22-.055.441-.055.662-.164 3.861 2.788 7.116 6.615 7.282 4.32.165 7.654 3.862 7.435 8.22 0 .276-.054.552-.109.828 0 .11-.055.165-.055.276-.054.165-.054.386-.109.551 0 .11-.055.22-.055.276l-.164.497c-.054.11-.054.165-.109.276-.055.165-.164.33-.219.496-.054.055-.054.165-.109.22-.11.221-.219.387-.328.608 0 .055-.055.055-.055.11-.164.22-.328.441-.492.607l-.164.165c-.11.166-.219.276-.383.386-.054.056-.163.11-.218.221-.11.11-.273.22-.383.331l-.11.11c.055 0 .11-.055.165-.055 5.63-1.986 9.403-7.117 9.677-13.075z"
        }
        fill={"currentColor"}
      ></path>

      <path
        d={
          "M15.31 43.304h-1.476a15.064 15.064 0 01-12.41-7.724c-.11-.22-.492-.11-.383.166C4.212 47.056 13.998 55 22.801 55c3.936 0 6.614-2.98 6.614-6.675 0-3.697-2.952-6.676-6.615-6.676h-.6c-.383 0-.875.166-.984.22-1.804.828-3.773 1.38-5.905 1.435z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default Group37Icon;
/* prettier-ignore-end */
