// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export function Group9Icon(props) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 30 25"}
      height={"1em"}
      width={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M29.181 13.844l-1.754-3.802v-1.82c0-4.55-3.64-8.222-8.19-8.222h-1.396a8.262 8.262 0 00-3.835.942A8.656 8.656 0 0010.074 0H8.677C3.899-.032 0 3.867 0 8.644c.032 2.665 1.202 5.167 3.25 6.857v8.904h18.685V20.18h4.225c.682 0 1.235-.552 1.235-1.234V14.72h1.202a.64.64 0 00.617-.617.345.345 0 00-.032-.26zM4.16 23.495v-1.82h1.982c.26.358.748.455 1.105.195.358-.26.455-.747.195-1.105-.26-.357-.747-.454-1.105-.194l-.195.194H4.16v-1.364h1.982c.26.357.748.455 1.105.195.358-.26.455-.748.195-1.105-.26-.358-.747-.455-1.105-.195l-.195.195H4.16v-3.445l-.162-.13A8.144 8.144 0 01.942 8.644C.942 4.354 4.419.91 8.709.878h1.365c4.29 0 7.734 3.477 7.734 7.766v1.885l1.56 3.412c.13.26 0 .553-.26.65-.066.033-.13.033-.195.033h-1.138v4.387c0 .617-.52 1.137-1.137 1.137h-4.322v3.315H4.159v.032zm20.473-12.511a.874.874 0 01-.878-.877c0-.488.39-.878.878-.878.487 0 .877.39.877.878 0 .487-.39.877-.877.877zm-10.4-1.105h1.852v1.495l-.974-.585h-.845l-.033-.91zm-5.947-6.24a4.563 4.563 0 00-4.55 4.55 4.563 4.563 0 004.55 4.55 4.563 4.563 0 004.55-4.55c-.033-2.535-2.047-4.55-4.55-4.55zm3.607 4.095h-1.267c-.195-.942-.942-1.722-1.885-1.885V4.582c1.625.195 2.925 1.495 3.152 3.152zm-3.152-.942c.455.162.813.487.943.942H8.74v-.942zm-.942.942h-.943c.13-.455.488-.812.943-.942v.942zm0 .91v.942c-.455-.162-.813-.487-.943-.942H7.8zm.91 0h.942c-.163.455-.487.78-.942.942v-.942zm-.91-4.062V5.85c-.943.195-1.723.943-1.885 1.885H4.647a3.59 3.59 0 013.152-3.152zM4.647 8.644h1.267c.195.942.942 1.69 1.885 1.885v1.267a3.591 3.591 0 01-3.152-3.152zm4.062 3.152V10.53c.942-.195 1.722-.942 1.884-1.885h1.268a3.59 3.59 0 01-3.152 3.152z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default Group9Icon;
/* prettier-ignore-end */
