// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export function IconTwitterIcon(props) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 25 25"}
      height={"1em"}
      width={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M21.84 6.915a7.449 7.449 0 01-1.732 1.647v.51a11.23 11.23 0 01-3.393 8.051 11.484 11.484 0 01-8.182 3.258 11.558 11.558 0 01-5.1-1.166.231.231 0 01-.14-.213V18.9a.24.24 0 01.243-.24 8.35 8.35 0 004.79-1.704 4.091 4.091 0 01-3.64-2.369.238.238 0 01.028-.234.244.244 0 01.216-.099c.478.047.96.004 1.422-.13a4.052 4.052 0 01-3.228-3.526.24.24 0 01.1-.214.246.246 0 01.237-.026c.466.203.969.31 1.478.314A3.939 3.939 0 013.34 6.1a.509.509 0 01.852-.167 11.621 11.621 0 007.87 3.592 3.666 3.666 0 01-.113-.926A4.037 4.037 0 0114.54 4.91a4.112 4.112 0 014.436.968 8.193 8.193 0 002.236-.768.16.16 0 01.169 0 .156.156 0 010 .167 4.06 4.06 0 01-1.647 1.85 8.104 8.104 0 001.871-.444.151.151 0 01.169 0 .156.156 0 01.066.231z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default IconTwitterIcon;
/* prettier-ignore-end */
