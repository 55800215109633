// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export function Group15Icon(props) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 10 10"}
      height={"1em"}
      width={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M8.16 0c.343.171.686.342.857.685.171.342 0 .685-.171 1.027-.171.172-.514.514-.685.857-2.055 2.055-4.11 4.28-6.336 6.336-.343.17-.514.342-.856.342a.942.942 0 01-.857-.514c-.17-.513-.17-.856.172-1.198l4.11-4.11L7.304.514C7.648.342 7.819.17 8.162 0z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default Group15Icon;
/* prettier-ignore-end */
