// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export function Group94Icon(props) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 24 44"}
      height={"1em"}
      width={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M11.16 9.254h1.168a11.92 11.92 0 019.82 6.111c.086.175.389.087.302-.13C19.985 6.241 12.198 0 5.234 0 2.12 0 0 2.357 0 5.282c0 2.924 2.336 5.281 5.234 5.281h.476c.303 0 .692-.13.779-.174 1.427-.655 2.984-1.091 4.671-1.135z"
        }
        fill={"currentColor"}
      ></path>

      <path
        d={
          "M16.693 28.374c.043-.175.043-.35.043-.524.13-3.056-2.206-5.631-5.234-5.762-3.417-.13-6.056-3.056-5.883-6.504 0-.218.043-.436.043-.655 0-.087.043-.13.043-.218.044-.13.044-.306.087-.437 0-.087.043-.174.087-.218l.13-.393c.043-.087.043-.13.086-.218.043-.13.13-.262.173-.35.043-.086.043-.13.086-.217l.26-.393c.043-.044.043-.087.086-.131.13-.175.217-.35.39-.48l.086-.088c.087-.13.216-.218.303-.349a.598.598 0 01.173-.13c.087-.088.216-.175.303-.263l.086-.087c-3.936 1.31-6.964 4.802-7.613 9.08a34.49 34.49 0 00-.13 1.047c0 .611 0 1.222.044 1.833.519 5.413 4.801 9.735 10.165 10.215.39.043.779.043 1.125.043 2.595-.218 4.671-2.182 5.06-4.801z"
        }
        fill={"currentColor"}
      ></path>

      <path
        d={
          "M23.228 22.264c.26-6.068-4.24-11.262-10.209-11.83-.173 0-.39-.043-.562-.043h-.52C9.3 10.52 7.18 12.53 6.79 15.149c-.043.174-.043.349-.043.523-.13 3.056 2.206 5.631 5.234 5.762 3.417.131 6.056 3.056 5.883 6.504 0 .219-.043.437-.087.655 0 .087-.043.131-.043.218-.043.131-.043.306-.086.437 0 .087-.044.174-.044.218l-.13.393c-.043.087-.043.13-.086.218-.043.131-.13.262-.173.393-.043.044-.043.131-.087.175-.086.174-.172.305-.259.48 0 .044-.043.044-.043.087-.13.175-.26.35-.39.48l-.13.131a1.013 1.013 0 01-.302.306c-.043.044-.13.087-.173.174-.087.088-.216.175-.303.262l-.087.088c.044 0 .087-.044.13-.044 4.456-1.572 7.44-5.631 7.657-10.345z"
        }
        fill={"currentColor"}
      ></path>

      <path
        d={
          "M12.114 34.263h-1.168a11.919 11.919 0 01-9.82-6.111c-.086-.175-.389-.088-.303.13 2.51 8.949 10.252 15.235 17.217 15.235 3.114 0 5.234-2.358 5.234-5.282 0-2.925-2.336-5.282-5.234-5.282h-.476c-.303 0-.692.131-.779.175-1.427.654-2.984 1.091-4.671 1.135z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default Group94Icon;
/* prettier-ignore-end */
