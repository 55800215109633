// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export function Vector33Icon(props) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 5 9"}
      height={"1em"}
      width={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M3.489.495C3.278.822.184 7.252.075 7.474c-.15.299-.095.728.355.956.5.257.844.113 1.03-.169.127-.19 2.799-5.8 3.048-6.307.338-.692.788-1.423.106-1.831-.563-.341-.956.109-1.125.372z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default Vector33Icon;
/* prettier-ignore-end */
