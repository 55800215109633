// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export function Group98Icon(props) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 25 45"}
      height={"1em"}
      width={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M11.54 9.57h1.209a12.325 12.325 0 0110.154 6.319c.089.18.402.09.313-.135C20.666 6.454 12.614 0 5.413 0 2.192 0 0 2.438 0 5.462c0 3.024 2.416 5.462 5.413 5.462h.492c.313 0 .715-.136.805-.18 1.476-.678 3.086-1.13 4.83-1.175z"
        }
        fill={"currentColor"}
      ></path>

      <path
        d={
          "M17.262 29.34c.045-.18.045-.36.045-.541.134-3.16-2.282-5.823-5.413-5.959-3.534-.135-6.262-3.16-6.083-6.725 0-.226.044-.452.044-.677 0-.09.045-.136.045-.226.045-.135.045-.316.09-.451 0-.09.044-.18.089-.226l.134-.406c.045-.09.045-.136.09-.226.044-.135.134-.27.179-.361.044-.09.044-.136.089-.226l.268-.406c.045-.045.045-.09.09-.136.134-.18.224-.36.402-.496l.09-.09c.09-.136.224-.226.313-.361a.63.63 0 01.179-.136c.09-.09.224-.18.313-.27l.09-.09c-4.071 1.353-7.202 4.964-7.873 9.388-.045.316-.09.677-.134 1.083 0 .632 0 1.264.044 1.896.537 5.597 4.966 10.066 10.512 10.563.403.045.805.045 1.163.045 2.684-.226 4.831-2.257 5.234-4.965z"
        }
        fill={"currentColor"}
      ></path>

      <path
        d={
          "M24.019 23.024c.268-6.274-4.384-11.646-10.557-12.233-.179 0-.402-.045-.581-.045h-.537c-2.729.136-4.92 2.212-5.323 4.92-.045.18-.045.361-.045.542-.134 3.16 2.281 5.823 5.413 5.958 3.533.136 6.262 3.16 6.083 6.726 0 .226-.045.451-.09.677 0 .09-.044.136-.044.226-.045.135-.045.316-.09.451 0 .09-.044.18-.044.226l-.134.406c-.045.09-.045.136-.09.226-.045.135-.134.27-.179.406-.045.045-.045.135-.09.18-.089.181-.178.317-.268.497 0 .045-.045.045-.045.09a4.81 4.81 0 01-.402.497l-.134.135a1.06 1.06 0 01-.313.316c-.045.045-.135.09-.18.18-.089.091-.223.181-.313.272l-.089.09c.045 0 .09-.045.134-.045 4.608-1.625 7.694-5.823 7.918-10.698z"
        }
        fill={"currentColor"}
      ></path>

      <path
        d={
          "M12.527 35.431h-1.208a12.325 12.325 0 01-10.154-6.32c-.09-.18-.403-.09-.313.136 2.594 9.254 10.601 15.754 17.803 15.754 3.22 0 5.412-2.438 5.412-5.462 0-3.025-2.415-5.462-5.412-5.462h-.492c-.313 0-.716.135-.805.18-1.476.678-3.087 1.129-4.831 1.174z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default Group98Icon;
/* prettier-ignore-end */
