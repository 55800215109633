// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PlasmicArrowRigth_autoLayoutHorizontal9__ip7GP {
  width: 48px;
  height: 48px;
  color: white;
  cursor: pointer;
  display: inline-flex;
  flex-direction: row;
  background: var(--token-Q2RSEYMom174bC1);
  border-radius: 50px;
  padding: 0;
  margin: 0;
  border: none;
}
.PlasmicArrowRigth_autoLayoutHorizontal9__ip7GP > .__wab_flex-container {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: calc(0px - 8px);
  width: calc(100% + 8px);
}
.PlasmicArrowRigth_autoLayoutHorizontal9__ip7GP > .__wab_flex-container > *,
.PlasmicArrowRigth_autoLayoutHorizontal9__ip7GP
  > .__wab_flex-container
  > .__wab_slot
  > *,
.PlasmicArrowRigth_autoLayoutHorizontal9__ip7GP > .__wab_flex-container > picture > img,
.PlasmicArrowRigth_autoLayoutHorizontal9__ip7GP
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: 8px;
}
.PlasmicArrowRigth_autoLayoutHorizontal9__ip7GP:hover {
  background: var(--token-jF8aRetmNAo8S2Y);
}
.PlasmicArrowRigth_svg__Ut76q {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: #f1f7f9;
  width: 24px;
  height: 24px;
  display: block;
  overflow: hidden;
  flex-shrink: 0;
}
`, "",{"version":3,"sources":["webpack://./src/components/plasmic/copy_of_blank_project/PlasmicArrowRigth.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,YAAY;EACZ,eAAe;EACf,oBAAoB;EACpB,mBAAmB;EACnB,wCAAwC;EACxC,mBAAmB;EACnB,UAAU;EACV,SAAS;EACT,YAAY;AACd;AACA;EACE,mBAAmB;EACnB,mBAAmB;EACnB,uBAAuB;EACvB,4BAA4B;EAC5B,uBAAuB;AACzB;AACA;;;;;;;;;;;EAWE,gBAAgB;AAClB;AACA;EACE,wCAAwC;AAC1C;AACA;EACE,kBAAkB;EAClB,iBAAiB;EACjB,eAAe;EACf,cAAc;EACd,WAAW;EACX,YAAY;EACZ,cAAc;EACd,gBAAgB;EAChB,cAAc;AAChB","sourcesContent":[".autoLayoutHorizontal9 {\n  width: 48px;\n  height: 48px;\n  color: white;\n  cursor: pointer;\n  display: inline-flex;\n  flex-direction: row;\n  background: var(--token-Q2RSEYMom174bC1);\n  border-radius: 50px;\n  padding: 0;\n  margin: 0;\n  border: none;\n}\n.autoLayoutHorizontal9 > :global(.__wab_flex-container) {\n  flex-direction: row;\n  align-items: center;\n  justify-content: center;\n  margin-left: calc(0px - 8px);\n  width: calc(100% + 8px);\n}\n.autoLayoutHorizontal9 > :global(.__wab_flex-container) > *,\n.autoLayoutHorizontal9\n  > :global(.__wab_flex-container)\n  > :global(.__wab_slot)\n  > *,\n.autoLayoutHorizontal9 > :global(.__wab_flex-container) > picture > img,\n.autoLayoutHorizontal9\n  > :global(.__wab_flex-container)\n  > :global(.__wab_slot)\n  > picture\n  > img {\n  margin-left: 8px;\n}\n.autoLayoutHorizontal9:hover {\n  background: var(--token-jF8aRetmNAo8S2Y);\n}\n.svg {\n  position: relative;\n  object-fit: cover;\n  max-width: 100%;\n  color: #f1f7f9;\n  width: 24px;\n  height: 24px;\n  display: block;\n  overflow: hidden;\n  flex-shrink: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"autoLayoutHorizontal9": `PlasmicArrowRigth_autoLayoutHorizontal9__ip7GP`,
	"svg": `PlasmicArrowRigth_svg__Ut76q`
};
export default ___CSS_LOADER_EXPORT___;
