// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export function Group31Icon(props) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 36 10"}
      height={"1em"}
      width={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M35.511 8.128c0-5.136-7.646-7.573-14.315-8.121l-3.024 3.878a.53.53 0 01-.415.204.52.52 0 01-.414-.204L14.313 0C10.602.285 0 1.67 0 8.128v1.058h35.508l.003-1.058z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default Group31Icon;
/* prettier-ignore-end */
