// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export function Group13Icon(props) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 18 16"}
      height={"1em"}
      width={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M2.563 11.86c1.027.171 2.055.343 2.91.514 2.398.514 4.796.856 7.022 1.37h.342c.514.171.857.685.685 1.199-.171.513-.514.856-1.199.684-1.37-.342-2.91-.513-4.28-.856-2.227-.513-4.453-.856-6.85-1.37H.85c-.856-.17-1.027-.685-.685-1.54 1.028-1.884 2.227-3.768 3.768-5.31C7.186 3.128 11.125 1.244 15.577.217c.171 0 .343 0 .343-.171.513-.172 1.027.17 1.198.685.172.513-.17 1.027-.856 1.198-1.027.343-2.226.514-3.254.856-4.452 1.542-7.877 4.281-10.445 8.22.171.514.171.685 0 .856z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default Group13Icon;
/* prettier-ignore-end */
