// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export function Group26Icon(props) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 19 29"}
      height={"1em"}
      width={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M.907 28.051L0 27.75c.025-.056 2.84-5.657 3.807-7.474.531-.999 1.625-1.67 2.729-2.45 1.037-.735 2.214-1.572 3.114-2.736 1.727-2.229 3.618-5.421 4.824-8.128C15.402 4.876 17.83.049 17.856 0l.583.4c-.024.05-2.404 4.775-3.322 6.846-1.227 2.756-3.153 6.004-4.911 8.276-.963 1.244-2.187 2.112-3.266 2.879-1.08.766-2.011 1.427-2.465 2.278-.956 1.8-3.54 7.316-3.568 7.372z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default Group26Icon;
/* prettier-ignore-end */
