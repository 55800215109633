// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export function IconLinkedIn2Icon(props) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 29 29"}
      height={"1em"}
      width={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        fillRule={"evenodd"}
        clipRule={"evenodd"}
        d={
          "M6.042 3.625h16.916a2.417 2.417 0 012.417 2.417v16.916a2.417 2.417 0 01-2.417 2.417H6.042a2.417 2.417 0 01-2.417-2.417V6.042a2.417 2.417 0 012.417-2.417zM9.667 21.75c.333 0 .604-.27.604-.604v-8.459a.604.604 0 00-.604-.604H7.854a.604.604 0 00-.604.604v8.459c0 .334.27.604.604.604h1.813zM8.76 10.875a1.813 1.813 0 110-3.625 1.813 1.813 0 010 3.625zM21.146 21.75c.334 0 .604-.27.604-.604v-5.559a3.746 3.746 0 00-3.287-3.794 3.625 3.625 0 00-3.359 1.62v-.726a.604.604 0 00-.604-.604h-1.813a.604.604 0 00-.604.604v8.459c0 .334.27.604.604.604H14.5c.334 0 .604-.27.604-.604v-4.531a1.813 1.813 0 013.625 0v4.53c0 .335.27.605.604.605h1.813z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default IconLinkedIn2Icon;
/* prettier-ignore-end */
