import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const CorefluxHub = ({ userName, className, corefluxHubClassName, text = "Coreflux Hub" }) => {
  const handleClick = () => {
    const emailUser = sessionStorage.getItem("userEmail")
    const data = {
      email: emailUser,
      name: userName
    }
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    var urlencoded = new URLSearchParams();
    urlencoded.append("email", data.email);
    urlencoded.append("name", data.name);
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: urlencoded,
      redirect: 'follow'
    };
    fetch(`https://login.coreflux.org/promotion-code`, requestOptions)
    .then((res) => console.log(res))
    .catch((error) => {console.log(error)})
  }
  return (
    <button className={`coreflux-hub ${className}`} onClick={() => handleClick()}>
      <div className={`text-wrapper ${corefluxHubClassName}`}>{text}</div>
    </button>
  );
};

CorefluxHub.propTypes = {
  property1: PropTypes.oneOf(["default"]),
  text: PropTypes.string,
};
