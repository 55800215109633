// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export function Group12Icon(props) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 16 18"}
      height={"1em"}
      width={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M3.596 14.727a24.474 24.474 0 003.425-2.398c3.425-3.082 5.308-6.85 6.165-11.302C13.356.342 13.699 0 14.213 0c.685 0 1.028.514 1.028 1.199-.172.856-.343 1.883-.685 2.74C12.671 9.76 9.247 14.042 3.938 16.953c-1.2.685-1.713.342-1.884-.856C1.37 12.5.685 8.733 0 5.137v-.856c.171-.342.514-.514 1.027-.514.343 0 .685.343.857.685.17 1.028.342 2.055.685 3.254.17 2.397.685 4.623 1.027 7.02z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default Group12Icon;
/* prettier-ignore-end */
