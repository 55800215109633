import React from "react";
import { Component } from "../../components/Component";
import "./cookieBannerStyle.css";
import CookieConsent from "react-cookie-consent";

export default function CookieBanner() {

  function cookieBannerDisplay(){
    document.getElementById("cookieBanner").style.display = "none"
    document.getElementById("cookieBanner").classList.add = "fadeOutAni"
    localStorage.setItem("cookieBannerDisplayed", "true");
  }

  const handleAcceptBtn = () => {
      cookieBannerDisplay()
  }

  const handleCancelBtn = () => {
      cookieBannerDisplay()
  }

  return (
    <CookieConsent
      location="bottom"
      containerClasses="background-cookie"
      enableDeclineButton={true}
      buttonText="Accept all Cookies"
      declineButtonText="Reject all Cookies"
      declineButtonClasses="decline-cookie-btn"
      flipButtons={true}
      buttonClasses="cookie-accept-btn"
      disableStyles={true}
      buttonWrapperClasses="cookie-btn-wrapper"
    >
        <div className="cookie-p">
            <div className="title-cookie-wrapper">
              <Component
                property1="style-1"
                propertyStyle="https://generation-sessions.s3.amazonaws.com/67a1c4d2277b7d4794c2c652f206b377/img/component-1.svg"
                propertyStyleClassName="component-1"
              />
              <h1 className="cookie-text-wrapper">Cookies</h1>
            </div>
            <p className="we-care-about-your">
              <span className="cookie-text">
                We care about your data, and we’d use cookies only to improve your experience.By using this website, you
                accept our <a href="/" className="cookie-policy">Cookies Policy</a>
              </span>
              <span className="cookie-text">.</span>
            </p>
          </div>

      {/* <div className="cookie-banner" id="cookieBanner">
      <div className="frame-wrapper">
        <div className="frame">
          <div className="cookie-p">
            <div className="frame-2">
              <Component
                property1="style-1"
                propertyStyle="https://generation-sessions.s3.amazonaws.com/67a1c4d2277b7d4794c2c652f206b377/img/component-1.svg"
                propertyStyleClassName="component-1"
              />
              <h1 className="text-wrapper">Cookies</h1>
            </div>
            <p className="we-care-about-your">
              <span className="span">
                We care about your data, and we’d use cookies only to improve your experience.By using this website, you
                accept our <a href="/" className="text-wrapper-3">Cookies Policy</a>
              </span>
              <span className="span">.</span>
            </p>
            <div className="frame-3">
              <button className="div-wrapper" onClick={() => handleAcceptBtn()}>
                <div className="text-wrapper-4">Accept All Cookies</div>
              </button>
              <button className="frame-4" onClick={() => handleCancelBtn()}>
                <div className="text-wrapper-5">Reject All Cookies</div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div> */}

    </CookieConsent>
  );
};
