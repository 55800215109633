import React from 'react'

export default function EmailInput3() {
  return (
    <div>
        <div class="input-group">
            <input type="email" class="input" id="Email" name="Email" placeholder="Email address" autocomplete="off"></input>
            <input class="button--submit" value=">" type="submit"></input>
        </div>
    </div>
  )
}
