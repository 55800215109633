// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export function Vector41Icon(props) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 5 5"}
      height={"1em"}
      width={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M.672 4.403c-.467 0-.797-.451-.627-.887.316-.802.726-1.578 1.217-2.268C1.99.228 3.374-.367 4.25.252c.898.632.781 1.896.152 2.507C3.05 4.07 1.344 4.403.672 4.403z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default Vector41Icon;
/* prettier-ignore-end */
