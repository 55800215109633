// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export function IconTwitter2Icon(props) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 29 29"}
      height={"1em"}
      width={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M25.342 8.117a8.686 8.686 0 01-2.009 1.934v.597a13.257 13.257 0 01-3.937 9.452A13.244 13.244 0 019.9 23.924a13.285 13.285 0 01-5.918-1.37.272.272 0 01-.163-.249v-.12c0-.155.127-.282.283-.282a9.619 9.619 0 005.56-1.999 4.745 4.745 0 01-4.225-2.781.283.283 0 01.283-.391 4.344 4.344 0 001.65-.152 4.736 4.736 0 01-3.746-4.14.283.283 0 01.39-.282 4.341 4.341 0 001.716.37A4.65 4.65 0 013.875 7.16a.586.586 0 01.988-.196 13.419 13.419 0 009.132 4.216 4.357 4.357 0 01-.13-1.087A4.733 4.733 0 0122.02 6.9a9.434 9.434 0 002.595-.902.185.185 0 01.195 0 .185.185 0 010 .196 4.747 4.747 0 01-1.91 2.173 9.313 9.313 0 002.172-.522.174.174 0 01.195 0 .184.184 0 01.076.272z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default IconTwitter2Icon;
/* prettier-ignore-end */
