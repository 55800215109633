/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { Property1Style2 } from "../../icons/Property1Style2";
import "./style.css";

export const Component = ({
  property1,
  propertyStyleClassName,
  propertyStyle = "https://generation-sessions.s3.amazonaws.com/67a1c4d2277b7d4794c2c652f206b377/img/property-1-style-1.svg",
}) => {
  return (
    <>
      {["style-1", "style-3"].includes(property1) && (
        <img
          className={`component ${propertyStyleClassName}`}
          alt="Property style"
          src={
            property1 === "style-1"
              ? propertyStyle
              : "https://generation-sessions.s3.amazonaws.com/67a1c4d2277b7d4794c2c652f206b377/img/property-1-style-3.svg"
          }
        />
      )}

      {property1 === "style-2" && <Property1Style2 className="property-style" />}
    </>
  );
};

Component.propTypes = {
  property1: PropTypes.oneOf(["style-3", "style-2", "style-1"]),
  propertyStyle: PropTypes.string,
};
