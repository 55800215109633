// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export function Group16Icon(props) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 9 10"}
      height={"1em"}
      width={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M7.791 0c.857 0 1.37.856 1.028 1.37-.171.171-.171.342-.343.514L1.798 8.562l-.171.171c-.514.343-1.028.514-1.37 0-.343-.342-.343-1.027 0-1.37l5.137-5.137C6.08 1.541 6.593 1.027 7.278.514 7.449.17 7.79 0 7.79 0z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default Group16Icon;
/* prettier-ignore-end */
