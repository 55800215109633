// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export function Group23Icon(props) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 5 33"}
      height={"1em"}
      width={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M3.776 32.713c-.078-.267-1.892-6.564-2.092-7.657-.193-1.048.13-2.426.475-3.881.175-.753.358-1.53.481-2.296.327-2.067.225-6.103-.597-9.82A692.963 692.963 0 000 .25L.703 0c.014.053 1.252 5.407 2.029 8.905.745 3.354 1.002 7.594.608 10.08-.127.794-.313 1.581-.492 2.348-.327 1.385-.633 2.69-.468 3.593.193 1.055 2.057 7.523 2.078 7.587l-.682.2z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default Group23Icon;
/* prettier-ignore-end */
