// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export function IconLinkedInIcon(props) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 25 25"}
      height={"1em"}
      width={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        fillRule={"evenodd"}
        clipRule={"evenodd"}
        d={
          "M5.207 3.088h14.578c1.15 0 2.083.922 2.083 2.059v14.411c0 1.137-.933 2.059-2.083 2.059H5.207c-1.15 0-2.083-.922-2.083-2.059V5.147a2.07 2.07 0 012.083-2.059zm3.124 15.44c.287 0 .52-.23.52-.514V10.81a.518.518 0 00-.52-.515H6.769a.517.517 0 00-.521.514v7.206c0 .284.233.515.52.515h1.563zM7.55 9.265A1.553 1.553 0 015.988 7.72c0-.852.699-1.544 1.562-1.544.862 0 1.562.692 1.562 1.544 0 .853-.7 1.544-1.562 1.544zm10.673 9.265c.288 0 .52-.23.52-.515V13.28c.035-1.636-1.189-3.032-2.832-3.232a3.136 3.136 0 00-2.894 1.38v-.618a.518.518 0 00-.521-.515h-1.562c-.288 0-.52.23-.52.514v7.206c0 .284.232.515.52.515h1.562c.287 0 .52-.23.52-.515v-3.86c0-.853.7-1.544 1.563-1.544.862 0 1.561.691 1.561 1.544v3.86c0 .284.234.515.521.515h1.562z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default IconLinkedInIcon;
/* prettier-ignore-end */
