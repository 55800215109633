// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export function Group24Icon(props) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 11 4"}
      height={"1em"}
      width={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M.33 3.278c-.186 0-.295-.161-.323-.351C-.067 2.456.39 1.549 4.655.61c3.128-.689 5.069-.77 5.677-.366.18.12.243.247.257.32.039.211-.162.366-.373.405a.388.388 0 01-.383-.155c-.148-.063-1.058-.32-5.013.552C1.403 2.118.71 2.828.71 2.832a.39.39 0 01-.323.443c-.015.003-.036.003-.057.003z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default Group24Icon;
/* prettier-ignore-end */
