import React from "react";
import "./codefestTermsStyle.css";
import Footer from "../../components/Footer";
import Nav from "../../components/Nav";
import MenuMobile from "../../components/MenuMobile";
import { hasVariant, ensureGlobalVariants } from "@plasmicapp/react-web";
import { useScreenVariants as useScreenVariantsuckDqGzTc66VzS0 } from "../../components/plasmic/copy_of_blank_project/PlasmicGlobalVariant__Screen";
import axios from "axios";
import { getAuth, signInWithCustomToken } from "firebase/auth";
import { useLocation } from "react-router-dom";
import LoadingScreen from 'react-loading-screen';

function getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export default function CodeFestTerms() {
  const globalVariants = ensureGlobalVariants({
    screen: useScreenVariantsuckDqGzTc66VzS0()
  });
  const locationRouter = useLocation()
  let userLogged = !locationRouter.state ? false : locationRouter.state.userLogged
  const [userName, setUserName] = React.useState(!locationRouter.state ? "" : locationRouter.state.userName)
  const [loadScreen, setLoadScreen] = React.useState(!locationRouter.state ? true : false)

  React.useEffect(() => {
    if (localStorage.getItem("btnLoginPressed") === "true") {
      window.location.href = "/"
    }
  }, []);

  React.useEffect(() => {
    if (locationRouter.state === null) {
      const userName = getCookie("userName")
      const userEmail = getCookie("userEmail")

      if (userName !== "" && userEmail !== "") {
        setUserName(userName)
        sessionStorage.setItem("userEmail", userEmail)
      }
    }
    window.scrollTo(0, 0);
  }, [locationRouter]);

  if (userName !== "") {
    userLogged = true
  } else {
    userLogged = false
  }

  if (locationRouter.state === null) {
    setTimeout(() => {
      setLoadScreen(false)
    }, 3500)
  }

  return (
    <LoadingScreen
      loading={loadScreen}
      bgColor='#0c182e'
      spinnerColor='transparent'
      textColor='rgba(255, 102, 51, 1)'
    >
      <div className="code-fest-terms">
        <div className="div-2">
          <div className="overlap-group">
            {(
              hasVariant(globalVariants, "screen", "mobileOnly") ? false : true
            ) ? (
              <Nav userLogged={userLogged} userName={userName} page="terms"></Nav>
            ) : null}
            {(
              hasVariant(globalVariants, "screen", "mobileOnly")
                ? true
                : hasVariant(globalVariants, "screen", "mobileOnly")
                  ? true
                  : false
            ) ? (
              <MenuMobile userLogged={userLogged} userName={userName}></MenuMobile>
            ) : null}
            <main className="main-content-wrapper">
              <div className="left-menu">
                <a className="terms-and-conditions" href="#terms">Terms And Conditions</a>
                <a className="cookies" href="#cookie">Cookies</a>
              </div>
              <div className="terms-service-wrapper">
                <img
                  className="line"
                  alt="Line"
                  src="https://generation-sessions.s3.amazonaws.com/4f4ef8e85b303f358504e8ec32335b74/img/line-1.svg"
                />
                <p className="coreflux-terms-of">
                  <h2 className="terms-title" id="terms">Coreflux – Terms of Use</h2>
                  <br />
                  Last Updated: July 12, 2023
                  <br />
                  <br />
                  <br />
                  <h2 className="terms-title">Acceptance of Terms</h2>
                  <br />
                  <br />
                  The services that Coreflux provides to you are subject to the following Terms of Use (&#34;TOU&#34;).
                  Coreflux reserves the right to update and modify the TOU at any time without notice to you. The most
                  current version of the TOU can be reviewed by clicking on the &#34;Terms of Use&#34; hypertext link
                  located at the bottom of our Web pages. When we make updates to the TOU, Coreflux will update the date at
                  the top of this page. By using the website after a new version of the TOU has been posted, you agree to
                  the terms of such new version.
                  <br />
                  <br />
                  <br />
                  <h2 className="terms-title">Description of Services</h2>
                  <br />
                  <br />
                  Through its network of Web properties, Coreflux provides you with access to a variety of resources,
                  including developer tools, download areas, communication forums and product information (collectively
                  &#34;Services&#34;). The Services, including any updates, enhancements, new features, and/or the addition
                  of any new Web properties, are subject to these TOU.
                  <br />
                  <br />
                  <br />
                  <h2 className="terms-title">Personal and Non-Commercial Use Limitation</h2>
                  <br />
                  <br />
                  Unless otherwise specified, the Services are for your personal and non-commercial use. You may not modify,
                  copy, distribute, transmit, display, perform, reproduce, publish, license, create derivative works from,
                  transfer, or sell any information, software, products or services obtained from the Services.
                  <br />
                  <br />
                  <br />
                  <h2 className="terms-title">Privacy and Protection of Personal Information</h2>
                  <br />
                  <br />
                  See the Privacy Statement disclosures relating to the collection and use of your personal data.
                  <br />
                  <br />
                  <br />
                  <h2 className="terms-title">Content</h2>
                  <br />
                  <br />
                  All content included in or made available through the Services, such as text, graphics, logos, icons,
                  images, sounds, music, digital downloads, data compilation, software, and documents is the exclusive
                  property of Coreflux or its content suppliers and is protected by the various applicable trade dress,
                  copyright, trademark, patent, and other intellectual property and unfair competition laws in the United
                  States and internationally. All rights not expressly granted to you in this TOU are reserved and retained
                  by Coreflux or its licensors, suppliers, publishers, rightsholders, or other content providers.
                  <br />
                  <br />
                  <br />
                  <h2 className="terms-title">Software</h2>
                  <br />
                  <br />
                  Any software that is made available to download from the Services (&#34;Software&#34;) is the copyrighted
                  work of Coreflux and/or its suppliers. Use of the Software is governed by the terms of the end user
                  license agreement, if any, which accompanies or is included with the Software (&#34;License
                  Agreement&#34;). An end user will be unable to install any Software that is accompanied by or includes a
                  License Agreement, unless he or she first agrees to the License Agreement terms. Third party scripts or
                  code, linked to or referenced from this website, are licensed to you by the third parties that own such
                  code, not by Coreflux. The Software is made available for download solely for use by end users according
                  to the License Agreement. Any reproduction or redistribution of the Software not in accordance with the
                  License Agreement is expressly prohibited by law, and may result in severe civil and criminal penalties.
                  Violators will be prosecuted to the maximum extent possible. Without limiting the foregoing, copying or
                  reproduction of the software to any other server or location for further reproduction or redistribution is
                  expressly prohibited, unless such reproduction or redistribution is expressly permitted by the license
                  agreement accompanying such software.
                  <br />
                  <br />
                  <br />
                  <h2 className="terms-title" id="cookie">Cookies</h2>
                  <br />
                  <br />
                  Any software that is made available to download from the Services (&#34;Software&#34;) is the copyrighted
                  work of Coreflux and/or its suppliers. Use of the Software is governed by the terms of the end user
                  license agreement, if any, which accompanies or is included with the Software (&#34;License
                  Agreement&#34;). An end user will be unable to install any Software that is accompanied by or includes a
                  License Agreement, unless he or she first agrees to the License Agreement terms. Third party scripts or
                  code, linked to or referenced from this website, are licensed to you by the third parties that own such
                  code, not by Coreflux. The Software is made available for download solely for use by end users according
                  to the License Agreement. Any reproduction or redistribution of the Software not in accordance with the
                  License Agreement is expressly prohibited by law, and may result in severe civil and criminal penalties.
                  Violators will be prosecuted to the maximum extent possible. Without limiting the foregoing, copying or
                  reproduction of the software to any other server or location for further reproduction or redistribution is
                  expressly prohibited, unless such reproduction or redistribution is expressly permitted by the license
                  agreement accompanying such software.
                </p>
              </div>
            </main>
            <Footer userLogged={userLogged} userName={userName}></Footer>
          </div>
        </div>
      </div>
    </LoadingScreen>
  );
};
