// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export function Group28Icon(props) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 10 6"}
      height={"1em"}
      width={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M9.364 5.272c-.675 0-2.5-.155-5.112-1.558C1.327 2.143.135.691.086.631A.39.39 0 01.142.086a.39.39 0 01.545.056c.01.014 1.16 1.4 3.93 2.887 3.038 1.63 4.926 1.462 4.947 1.459.215-.025.4.13.425.344.025.211-.13.4-.34.425a2.086 2.086 0 01-.285.015z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default Group28Icon;
/* prettier-ignore-end */
