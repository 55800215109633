// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export function Vector4Icon(props) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 19 19"}
      height={"1em"}
      width={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        fillRule={"evenodd"}
        clipRule={"evenodd"}
        d={
          "M9.372 0C4.198 0 0 4.15 0 9.264c0 5.115 4.198 9.265 9.372 9.265 5.164 0 9.372-4.15 9.372-9.265C18.744 4.15 14.536 0 9.372 0zm6.19 4.27a7.822 7.822 0 011.81 4.924c-.265-.05-2.908-.583-5.57-.251-.062-.13-.113-.271-.174-.412a23.37 23.37 0 00-.528-1.146c2.948-1.185 4.29-2.893 4.462-3.115zm-6.19-2.903a8.04 8.04 0 015.306 1.99c-.142.2-1.352 1.798-4.198 2.853a42.18 42.18 0 00-2.99-4.632 8.23 8.23 0 011.88-.211zm-3.405.743c.213.281 1.636 2.24 2.968 4.572-3.74.985-7.044.965-7.4.965.518-2.452 2.195-4.492 4.432-5.537zM1.362 9.275v-.242c.346.01 4.229.06 8.223-1.125.234.442.448.894.65 1.346-.1.03-.213.06-.314.09-4.127 1.317-6.323 4.914-6.506 5.216a7.844 7.844 0 01-2.053-5.285zm8.01 7.907a8.007 8.007 0 01-4.91-1.668c.143-.291 1.769-3.386 6.282-4.943.02-.01.03-.01.051-.02a32.412 32.412 0 011.708 5.998 7.953 7.953 0 01-3.131.633zm4.462-1.356c-.081-.482-.508-2.793-1.555-5.637 2.51-.392 4.706.251 4.98.342-.345 2.2-1.626 4.1-3.425 5.295z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default Vector4Icon;
/* prettier-ignore-end */
