// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export function Ears2Icon(props) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 27 9"}
      height={"1em"}
      width={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M22.982 0H3.765C1.695 0 0 1.804 0 4.011 0 6.22 1.695 8.023 3.765 8.023h19.213c2.07 0 3.765-1.807 3.765-4.012C26.747 1.807 25.053 0 22.982 0z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default Ears2Icon;
/* prettier-ignore-end */
