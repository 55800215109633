// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export function Group32Icon(props) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 30 30"}
      height={"1em"}
      width={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M4.378.592c4.568 0 6.837 4.912 7.57 6.901.103.284.5.292.619.016l.67-1.564C12.292 2.932 9.7 0 5.274 0 3.442 0 1.506.606 0 1.883A8.03 8.03 0 014.378.593zM21.202 0c-1.405 0-2.685.305-3.684.922.701-.271 1.57-.33 2.43-.33 4.306 0 8.16 3.265 6.42 10.811-1.492 6.479-10.215 14.339-12.88 17.338-.17.19-.228.499-.228.499s13.65-9.884 15.507-17.818C30.621 3.49 25.97 0 21.202 0z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default Group32Icon;
/* prettier-ignore-end */
