// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export function Vector65Icon(props) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 7 5"}
      height={"1em"}
      width={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M.232 4.843a.213.213 0 01-.228-.255C.012 4.548.87.681 6.356.003c.12-.02.223.068.237.184a.213.213 0 01-.185.238C1.237 1.063.452 4.528.422 4.675a.213.213 0 01-.19.168z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default Vector65Icon;
/* prettier-ignore-end */
