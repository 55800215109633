import React from "react";
import sty from "../plasmic/copy_of_blank_project/PlasmicHomepage.module.css"
import { classNames } from "@plasmicapp/react-web";
import "./style.css";

export const Banner = ({ srcVideo }) => {
  return (
    <div className="BANNER">
      <video src={srcVideo} typeof="video/mp4"
        autoPlay={true}
        className={classNames("__wab_instance", sty.embedHtml, "barcelona-video")}
        loop={true}
        muted={true}
        playsInline={true}
      >
      </video>
      <div className="overlap-wrapper">
        <div className="overlap">
          <div className="group">
            <div className="overlap-group">
              <div className="rectangle" />
              <div className="div" />
            </div>
          </div>
          <div className="title-and-sub-wrapper">
            <div className="title-wrapper-barcelona">
              <img className="group-2" alt="Group" src="https://c.animaapp.com/f2IYYS4T/img/group-3850@2x.png" />
              <img className="BARCELONA" alt="Barcelona" src="https://c.animaapp.com/f2IYYS4T/img/barcelona.png" />
            </div>
            <div className="coming-soon-wrapper-bacerlona">
              <div className="comming-soon">COMING SOON</div>
              <img className="mask-group-3" alt="Mask group" src="https://c.animaapp.com/f2IYYS4T/img/mask-group@2x.png" />
            </div>
          </div>
          <img
            className="robot-hand-finger"
            alt="Robot hand finger"
            src="https://c.animaapp.com/f2IYYS4T/img/robot-hand-finger-pointing-ai-technology-backgrou-2022-09-16-09-.png"
          />
        </div>
      </div>
    </div>
  );
};
