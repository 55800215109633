// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export function Group33Icon(props) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 6 9"}
      height={"1em"}
      width={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M.975 1.61C1.971.367 3.751-.661 5.26.523c.815.64.46 1.953-.271 2.505-1.063.808-1.987 1.294-2.635 2.558-.388.76-.622 1.591-.741 2.439a.348.348 0 01-.651.122C-.16 6.16-.478 3.424.975 1.61z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default Group33Icon;
/* prettier-ignore-end */
